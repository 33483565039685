<template>
  <div>
    <el-card class='box-card'>
      <div slot='header' class='clearfix'>
        <div v-if='info.id'>
           <span @click='init' style='font-size: 18px;font-weight: bold;'>【初选选品】 {{
               `${$utils.parseTime(info.start_at, '{y}-{m}-{d}')} ${info.nickname || ''}
                                  ${info.platform_name || ''} ${info.theme || ''}`
             }}
        </span>(发起人：{{ info.leader_name || '' }} 进度：<label v-if='info.progress_status'
                                                           :style='`text-align: center;border-radius: 5px;padding:2px 8px;margin:1px;border: #7d8086 1px solid;background-color:${progressStatusColorMap[info.progress_status]}`'>{{ progressStatusMap[info.progress_status] || '--'
          }}</label>)
        </div>

      </div>

      <el-row :gutter='15'>
        <el-col :span='24'>
          <el-form size='small' inline>
            <el-form-item>
              <el-button type='primary' icon='el-icon-shopping-cart-full' @click='showPreCard'
                         v-if="userPermissions.indexOf('product_selection_save')>-1">
                初选商品({{ dataList.length }})
              </el-button>
            </el-form-item>
            <el-form-item>
              <el-select v-model='searchCondition.leader_id' filterable
                         v-if="userPermissions.indexOf('product_selection_preview')>-1"
                         placeholder='负责人' @change='getLbScheduleProducts' clearable>
                <el-option :label='item.leader_name' :value='item.leader_id' v-for='(item,index) in searchLeaderOptions'
                           :key='index'>
                </el-option>
              </el-select>
              <span v-else>
            负责人：  {{ userInfo.nickname }}
            </span>
            </el-form-item>
            <el-form-item>

              <el-select v-model='searchProductTitle' filterable allow-create placeholder='商品名称(可自定义)' clearable>
                <el-option :label='item' :value='item' v-for='(item,index) in searchTitleOptions'
                           :key='index'></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type='primary' icon='el-icon-search' @click='getLbScheduleProducts'>搜索</el-button>
            </el-form-item>
            <el-form-item>
              <el-popover
                placement='right'
                width='400'
                trigger='click' @hide='hideTotalDiv'>
                <div>
                  <div class='total-form'>
                    <el-form ref='elForm' :model='info' size='small' :rules='rules' :inline='false'
                             label-width='120'>
                      <el-form-item label='红人' prop='nickname'>
                        <span> {{ info.nickname }}</span>
                      </el-form-item>
                      <el-form-item label='排期' prop='id'>
                        {{
                          `${$utils.parseTime(info.start_at, '{y}-{m}-{d}')} ${info.nickname}
                                ${info.platform_name || info.platform_code} ${info.theme}`
                        }}
                      </el-form-item>
                      <el-form-item label='' label-width='60'>
                        <div class='default-table'>
                          <el-table :data='categoryStatistics' :show-header='false' show-summary sum-text='选品总数' border>
                            <el-table-column label='类目' prop='category' align='center'
                                             min-width='60'></el-table-column>
                            <el-table-column label='数量' prop='total' align='center'
                                             min-width='100'></el-table-column>
                          </el-table>
                        </div>
                      </el-form-item>
                    </el-form>
                    <el-row style='margin-bottom: 20px'>
                      <el-col :span='24'>
                        最后保存时间： {{ info.selected_time || '-' }}
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <el-button slot='reference' @click='showForm=!showForm'>汇总合计&nbsp;
                  <!--                  <i :class="[showForm?'el-icon-arrow-right':'el-icon-arrow-left']" style="size: 1.2em"></i>-->
                  <i class='el-icon-arrow-right' v-if='showForm'></i>
                  <i class='el-icon-arrow-left' v-else></i>
                </el-button>
                <!--                    <el-button slot="reference">click 激活</el-button>-->
              </el-popover>

            </el-form-item>
            <el-form-item>
              <el-button type='primary' @click='modifyBtn' v-loading.fullscreen.lock='tableLoading'
                         :icon="showSaveBtn?'el-icon-folder':'el-icon-edit'"
                         v-if="userPermissions.indexOf('product_selection_save')>-1">
                <i v-show='tableLoading' class='el-icon-loading'></i> {{ showSaveBtn ? '保存' : '修改' }}
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>

      <div class='default-table'>
        <!--      商品列表-->
        <el-table
          :data='dataList.filter(data => (!searchProductTitle|| data.title.includes(searchProductTitle)))'
          max-height='1000' border>
          <el-table-column label='操作' width='55' align='center'
                           v-if="userPermissions.indexOf('product_selection_change')>-1&&info.progress_status!==5">
            >
            <template slot-scope='{row}'>
              <el-button @click='delProducts(row)' type='text' icon='el-icon-remove-outline'
                         style='font-size: 1.2em' />
            </template>
          </el-table-column>
          <el-table-column type='index' label='序号' align='center' width='50'></el-table-column>
          <!--        <el-table-column type='selection' align='center' width='50'></el-table-column>-->
          <el-table-column label='商品名称' prop='title' width='160' header-align='center' align='left'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-tag :type="`${row.is_ack==='Y'?'success':'danger'}`" effect='plain' style='margin-right: 2px'>
                {{ row.is_ack === 'Y' ? '已确认' : '待确认' }}
              </el-tag>
              <span :title='`${row.id}-${row.product_id}`'>{{ row.title || row.id }}</span>
            </template>
          </el-table-column>
          <el-table-column label='产品图' prop='cover' width='70' align='center'>
            <template slot-scope='{row}'>
              <product-image :image-url='row.cover_url' />
            </template>
          </el-table-column>
          <el-table-column label='类目' prop='category_id' min-width='80' align='center' :sortable='true'>
            <template slot-scope='{row}'>
              <span>{{ row.category || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='规格' prop='spec_name' align='center' min-width='120'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-input v-model='row.spec_name' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.spec_name || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='合作方式' prop='mode' align='center' min-width='130'>
            <template slot-scope='{row}'>
              <el-cascader
                v-if='showSaveBtn'
                placeholder='选择分类'
                :options='selectedList'
                v-model='row.modeArr'
                show-all-levels
                :props='cascaderProps'
                clearable
                size='mini'
                @change='cascaderModeChange(row)'
                filterable>
              </el-cascader>
              <span v-else>{{ row.mode_alias || '' }} </span></template>
          </el-table-column>
          <el-table-column label='日常价(元)' prop='price' align='center' min-width='160'>
            <template slot-scope='{row}'>
              <el-input v-model='row.price' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.price || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='直播价(元)' prop='lb_price' align='center' min-width='140'>
            <template slot-scope='{row}'>
              <el-input v-model='row.lb_price' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.lb_price || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='赠品' prop='gift' align='center' min-width='160'>
            <template slot-scope='{row}'>
              <el-input v-model='row.gift' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.gift || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='独家机制' prop='other_activity' align='center' min-width='120'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-input v-model='row.other_activity' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.other_activity || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='坑位费' prop='ad_space_fee' align='center' min-width='110'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-input oninput="value=value.replace(/[^0-9.]/g,'')"
                        v-model='row.ad_space_fee' placeholder='必填' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.ad_space_fee || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='线上佣金(%)' prop='online_cmn' align='center' min-width='110'>
            <template slot-scope='{row}'>
              <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='row.online_cmn' placeholder='必填'
                        v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.online_cmn || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='线下佣金(%)' prop='offline_cmn' align='center' min-width='110'>
            <template slot-scope='{row}'>
              <el-input oninput="value=value.replace(/[^0-9.]/g,'')" v-model='row.offline_cmn' placeholder='必填'
                        v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.offline_cmn || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='品牌支持' prop='brand_support' align='center' min-width='120'>
            <template slot-scope='{row}'>
              <el-select v-model='row.brand_support' filterable allow-create placeholder='可自定义' v-if='showSaveBtn'
                         default-first-option
                         clearable>
                <el-option label='福袋' value='福袋'></el-option>
                <el-option label='秒杀' value='秒杀'></el-option>
              </el-select>
              <span v-else>{{ row.brand_support || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='库存/保质期' prop='stock_info' align='center' min-width='120'>
            <template slot-scope='{row}'>
              <el-input v-model='row.stock_info' placeholder='库存/保质期' v-if='showSaveBtn'></el-input>
              <span v-else>{{ row.stock_info || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='是否样品' prop='sample_info' align='center' min-width='120'>
            <template slot-scope='{row}'>
              <el-select v-model='row.sample_info' filterable allow-create placeholder='可自定义' v-if='showSaveBtn'
                         default-first-option
                         clearable>
                <el-option label='是' value='是'></el-option>
                <el-option label='否' value='否'></el-option>
              </el-select>
              <span v-else>{{ row.sample_info || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='选品原因' prop='reason' align='center' min-width='140'>
            <template slot-scope='{row}'>
              <el-input v-model='row.reason' placeholder='选品原因' maxlength='100' v-if='showSaveBtn' clearable></el-input>
              <span v-else>{{ row.reason || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='负责人' prop='leader_id' align='center' width='140' :sortable='true'>
            <!--              <template slot="header">-->
            <!--                <el-input v-model="searchLeader" size="mini" placeholder="负责人" style="width: 120px" clearable/>-->
            <!--              </template>-->
            <template slot-scope='{row}'>
              <span>{{ row.leader_name || '' }}</span>
            </template>
          </el-table-column>

          <el-table-column label='备注信息' prop='remark' align='center' min-width='160'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <el-input v-model='row.remark' placeholder='备注信息' maxlength='100' v-if='showSaveBtn' clearable></el-input>
              <span v-else>{{ row.remark || '' }} </span>
            </template>
          </el-table-column>
          <el-table-column label='店铺名称' prop='company_name' align='center' min-width='120' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span>{{ row.company_name || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='初选时间' prop='created_at' min-width='140' align='center'></el-table-column>
          <!--            <el-table-column label="修改人" prop="update_by" min-width="140" align="center"></el-table-column>-->
        </el-table>
      </div>

      <el-row>
        <el-col :span='24'>
          <Tips :title='`说明：`' :data='tipList' />
        </el-col>
      </el-row>

    </el-card>

    <ProductTable ref='LbProductSelect' :visible.sync='addPreCard' :lbObj='info'
                  @close='init' v-if='info.id'></ProductTable>
    <!--    全部选品-->
  </div>
</template>

<script>
import ArtistSearch from '@/components/artist/ArtistSearch'
import { mapGetters } from 'vuex'
import ExportBtn from '@/components/export/ExportBtn'
import ProductImage from '../product/ProductImage'
import ProductTable from '@/pages/lb/product_selection/ProductTable.vue'

export default {
  name: 'SelectionCard',
  components: { ProductImage, ExportBtn, ArtistSearch, ProductTable },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    cascaderProps() {
      return { value: 'id', label: 'display_name', checkStrictly: true, multiple: false }
    },
    progressStatusMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.label
      })
      return map
    },
    progressStatusColorMap() {
      let map = {}
      this.progressStatusOptions.forEach((item) => {
        map[item.value] = item.color_value
      })
      return map
    },
    exportTitle() {
      return this.userPermissions.indexOf('product_selection_preview') > -1 ? `导出全部选品` : '导出我的选品'
    },
    dialogTitle() {
      return `${this.$utils.parseTime(this.info.start_at, '{y}-{m}-{d}')} ${this.info.nickname}
                                ${this.info.platform_name || this.info.platform_code} ${this.info.theme}`
    },
    searchTitleOptions() {
      let options = []
      this.dataList.forEach((item) => {
        if (options.indexOf(item.title) === -1)
          options.push(item.title)
      })
      return options
    },
    categoryStatistics() {
      let sData = []
      this.dataList.forEach((item) => {
        let index = sData.findIndex(value => value.category_id === item.category_id)
        if (index === -1)
          if (index === -1)
            sData.push({ category_id: item.category_id, category: item.category, total: 1 })
          else {
            sData[index]['total'] += 1
          }
      })
      return sData
    }
  },
  data() {
    return {
      showForm: true,
      showNotify: false,
      searchCondition: {},
      rules: {
        nickname: { required: true, message: '请选择红人（KOL）', trigger: 'change' },
        id: { required: true, message: '请选择排期', trigger: 'change' },
        ad_space_fee: { required: true, message: '请输入坑位费', trigger: 'change' }
      },
      searchLeader: '',
      searchProductTitle: '',
      addPreCard: false,
      dataList: [],
      tableLoading: false,
      tipList: [
        '1、选品数据来自"产品库"，需要添加产品后才能进行选品',
        '2、刷新已选品：若已选品，但是显示选品个数不对，请点击按钮“已选品商品”刷新',
        '3、预览选品：1）全部选品：所有负责人的针对当前排期的选品情况 2）我的选品：仅显示负责人是当前登录账号的选品'
      ],
      showSaveBtn: false,
      id: null,
      info: {},
      progressStatusOptions: [
        { label: '新创建', value: 1, color_value: '#FFFFFF', class_name: 'status-new' },
        { label: '已发需求', value: 2, color_value: '#67C23A', class_name: 'status-launch' },
        { label: '初选完成', value: 3, color_value: '#409EFF', class_name: 'status-original' },
        { label: '确认完成', value: 4, color_value: '#E6A23C', class_name: 'status-ack' },
        { label: '锁定表格', value: 5, color_value: '#F56C6C', class_name: 'status-lock' }
      ],
      selectedList: []
      // progressStatusOptions: [
      //   { label: '新创建', value: 1, color_value: '#ffffff' },
      //   { label: '已发需求', value: 2, color_value: '#309d25' },
      //   { label: '初选完成', value: 3, color_value: '#2843cc' },
      //   { label: '确认完成', value: 4, color_value: '#c9913f' },
      //   { label: '锁定表格', value: 5, color_value: '#be2929' }
      // ]
      , searchLeaderOptions: []
    }
  },
  methods: {
    cascaderModeChange(row) {
      let modeArr = row.modeArr
      let mode = modeArr[modeArr.length - 1]
      let data = this.selectedList.find(value => value.id === mode)
      if (data)
        row.mode_alias = data.display_name || ''

      row.mode = mode
    },
    modifyBtn() {

      if (!this.showSaveBtn) {
        this.showSaveBtn = true
        this.getSelect()

      } else {
        this.handleConfirm()
      }

    },
    handleConfirm() {
      this.$confirm('确认修改选品信息，本次修改仅对当前排期有效, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.saveSelections()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })

    },
    async saveSelections() {
      this.tableLoading = true
      let ids = this.dataList.map((item) => {
        let row = {}
        row['id'] = item.id || null
        row['product_id'] = item.product_id
        row['price'] = item.price || ''
        row['brand_support'] = item.brand_support || ''
        row['stock_info'] = item.stock_info || ''
        row['sample_info'] = item.sample_info || ''
        row['lb_price'] = item.lb_price || ''
        row['gift'] = item.gift || ''
        row['online_cmn'] = item.online_cmn || ''
        row['offline_cmn'] = item.offline_cmn || ''
        row['reason'] = item.reason || ''
        row['remark'] = item.remark || ''
        row['leader_id'] = item.leader_id || ''
        row['leader_name'] = item.leader_name || ''
        row['ad_space_fee'] = item.ad_space_fee || null
        row['spec_name'] = item.spec_name || null
        //合作方式别名
        row['mode_alias'] = item.mode_alias || null
        //合作方式
        row['mode'] = item.mode
        row['price'] = item.price || null
        row['other_activity'] = item.other_activity || null
        row['spec_name'] = item.spec_name || null
        //合作方式别名
        row['mode_alias'] = item.mode_alias || null
        //合作方式
        row['mode'] = item.mode
        row['price'] = item.price || null
        row['other_activity'] = item.other_activity || null
        return row
      })
      let postData = {
        lb_id: this.info.id,
        product_rows: ids
      }
      await this.saveProducts(postData)
    },
    async saveProducts(postData) {
      let info = await this.$api.saveLbScheduleProducts(postData)
      if (info) {
        this.$notify.success('保存成功')
        this.tableLoading = false
        this.showSaveBtn = false
      }
    },
    async init() {
      await this.getInfo()
      await this.getLbScheduleProducts()
      if (this.userPermissions.indexOf('product_selection_preview') > -1) {
        await this.getLbLeaderLdOptions()
      }
    },
    async getLbLeaderLdOptions() {
      //获取负责人下拉信息
      let { options } = await this.$api.getLbLeaderLdOptions({ lb_id: this.id })
      this.searchLeaderOptions = options
    },
    async getInfo() {
      if (this.id) {
        this.tableLoading = true
        //获取当前登录人信息
        let { info } = await this.$api.getLbScheduleInfo(this.id)
        this.info = { ...info }
        this.tableLoading = false
      }
    },
    async getLbScheduleProducts() {
      //获取当前全部选品
      let params = {}
      if (this.searchCondition.leader_id) {
        params['leader_id'] = this.searchCondition.leader_id
      }
      if (this.userPermissions.indexOf('product_selection_preview') === -1) {
        params['leader_id'] = this.userInfo.id
      }
      params['lb_id'] = this.info.id
      let { list } = await this.$api.getLbScheduleAckProducts(params)
      list = list.map((item) => {
        item.modeArr = [item.mode]
        return item
      })
      this.dataList = list || []
    },
    async delRow(row) {
      let index = this.dataList.findIndex(value => value.id === row.id)
      if (index !== -1) {
        this.dataList.splice(index, 1)
      }
      this.$emit('del', row)
    },

    async delProducts(row) {
      if (row.id) {
        this.$confirm(`确定移除选品：${row.title}/${row.category}/${row.spec_name} ?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let info = await this.$api.delLbScheduleProducts(row.id)
          if (info) {
            this.$message.success('删除成功')
            await this.delRow(row)
          }
        }).catch(() => {
          this.$message.info('已取消删除')
          // this.close()
          return false
        })
      } else {
        await this.delRow(row)
      }
    },
    hideTotalDiv() {
      this.showForm = false
    },
    showPreCard() {
      this.addPreCard = true
    },
    async getSelect() {
      let { list } = await this.$api.getCategoryListByIdentify(`mode-category`)
      this.selectedList = list
    }
  },
  mounted() {
    this.id = this.$route.query && this.$route.query.lb_id
    this.init()
  }
}
</script>

<style scoped>
.total-card {
  background: #0C0C0C;
  opacity: 0.8;
  padding: 20px;
}

.total-form {
  /*background: #f2f2f2;*/
  padding: 20px 20px;
  border-radius: 5px;
}
</style>
