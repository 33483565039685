<template>
  <div>
      <SelectionCard />
  </div>

</template>

<script>

import SelectionCard from '@/pages/lb/product_selection/SelectionCard'

export default {
  name: "SelectionDrawer",
  components: {SelectionCard},
  props: {
    data: {
      type: Array
    },
    lbId: {
      type: [Number, String]
    },
    leader: {
      type: Object,
      default() {
        return {};
      }
    },
    open: {
      type: Boolean,
      default() {
        return false
      }
    },
    selectType: {
      type: String,
      default() {
        // all:全部选品,personal:个人负责的选品
        return 'all';
      }
    }
  },
  data() {
    return {
      size: '99%',
      // drawer: false,
      direction: 'rtl',
      drawerTitle: '',
      // dataList: []
    };
  },
  // watch: {
  //   open: {
  //     immediate: true,
  //     handler(val) {
  //       if(val){
  //
  //       }
  //       // this.drawer = val
  //     }
  //   }
  // },
  methods: {
    setDrawerTitle(val) {
      let prefTitle = this.leader.leader_id ? `【${this.leader.leader_name}】负责选品` : '全部选品'

      return this.drawerTitle = `${prefTitle}：${val}`
    },
    delRow(val){
      this.$emit('del',val)
    },
    savedEvent(val){
      this.$emit('saved',val)
    }

  }
}
</script>

<style scoped>

</style>
