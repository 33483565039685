<template>
  <div>
    <el-drawer size='85%' v-bind='$attrs' v-on='$listeners' @open='onOpen' @close='onClose' :title='`产品库`'
               :append-to-body='false'>
      <template slot='title'>
        <h2>选品库 ( {{
            `${$utils.parseTime(lbObj.start_at, '{y}-{m}-{d}')} ${lbObj.nickname}
                                ${lbObj.platform_name || lbObj.platform_code} ${lbObj.theme}`
          }})</h2>
      </template>
      <div style='padding-left: 10px'>
        <el-form :model='searchCondition' inline size='mini'>

          <el-form-item label='负责人'>
            <LeaderSearch v-if="userPermissions.indexOf('product_selection_preview')>-1"
                          :keyword.sync='searchCondition.leader_name' @selected='selectedLeader' />
            <span v-else>{{ searchCondition.leader_name }}</span>
          </el-form-item>
          <el-form-item label='类目'>
            <CategoryCascader :identify='`product`' @cascaderChange='cascaderChange' />
          </el-form-item>
          <el-form-item label='商品名称'>
            <el-input placeholder='商品名称' @clear='handleQuery' v-model='searchCondition.title'
                      clearable />
          </el-form-item>
          <el-form-item label='品牌'>
            <template slot='label'>
              <el-link @click.stop.native='getBrand(true)'><i
                :class='[loadingBrandStatus?"el-icon-loading":"el-icon-refresh"]'></i>品牌
              </el-link>
            </template>
            <el-select clearable v-model='searchCondition.brand_id'
                       filterable
                       style='width: 100%' @focus='getBrand' placeholder='请选择'>
              <el-option
                v-for='item in brandOptions'
                :key='item.id'
                :label='item.name'
                :value='item.id'>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' @click='handleQuery'>搜索</el-button>
          </el-form-item>
        </el-form>

        <!--        选品库-->
        <div class='default-table' v-loading='loading' element-loading-text='加载中……'
             element-loading-spinner='el-icon-loading'>
          <el-table :data='dataList' ref='productMultipleTable' row-key='id' @selection-change='handleSelectRow'
                    highlight-current-row border>
            <el-table-column type='selection' :reserve-selection='true' width='55' :selectable='selectable'
                             v-if='editMode'></el-table-column>
            <!--            <el-table-column type='selection' :reserve-selection='true' width='55' v-if='editMode'></el-table-column>-->
            <el-table-column type='index' label='序号' width='55' align='center'></el-table-column>
            <!--            <el-table-column label='ID' prop='id' width='55' align='center'></el-table-column>-->
            <!--          <el-table-column label='录入时间' prop='created_at' min-width='100' align='center'>-->
            <!--            <template slot-scope='{row}'>-->
            <!--              <span>{{ $utils.parseTime(row.created_at, '{y}-{m}-{d}') }}</span>-->
            <!--            </template>-->
            <!--          </el-table-column>-->
            <el-table-column label='商品名称' prop='title' min-width='260' header-align='center' align='left'
                             show-overflow-tooltip>
              <template slot-scope='{row}'>
                <el-tag :type="`${row.is_ack==='Y'?'success':'danger'}`" effect='plain' style='margin-right: 2px'
                        v-if='row.lb_product_id'>
                  {{ row.is_ack === 'Y' ? '确认' : '初选' }}
                </el-tag>
                <span :title='`${row.id}`'>{{ row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column label='产品图' prop='cover' width='70' align='center'>
              <template slot-scope='{row}'>
                <product-image :image-url='row.cover_url' />
              </template>
            </el-table-column>
            <el-table-column label='品牌' prop='brand_name' width='100' align='center'></el-table-column>
            <el-table-column label='类目' prop='category_id' min-width='80' align='center'>
              <template slot-scope='{row}'>
                <span>{{ row.category || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='规格' prop='spec_name' align='center' min-width='100'
                             show-overflow-tooltip></el-table-column>
            <el-table-column label='合作方式' prop='mode' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.mode_alias || '' }}</span>
              </template>
            </el-table-column>
            <!--        <el-table-column label="日常价/直播价" align="center" min-width="100">-->
            <!--          <template slot-scope="{row}">-->
            <!--            <span>{{ row.price || '' }} 元 / {{ row.lb_price || '' }} 元</span>-->
            <!--          </template>-->
            <!--        </el-table-column>-->
            <el-table-column label='日常价(元)' align='center' min-width='140'>
              <template slot-scope='{row}'>
                <span>{{ row.price || '/' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='直播价(元)' align='center' min-width='140'>
              <template slot-scope='{row}'>
                <span>{{ row.lb_price || '/' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='赠品' align='center' min-width='120' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.gift || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='独家机制' prop='other_activity' align='center' min-width='120' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.other_activity || '' }} </span>
              </template>
            </el-table-column>
            <el-table-column label='坑位费(元)' prop='ad_space_fee' align='center' min-width='110'>
              <template slot-scope='{row}'>
                <span>{{ row.ad_space_fee || '-' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='线上/线下佣金' align='center' min-width='120'>
              <template slot-scope='{row}'>
                <span>{{ row.online_cmn || '' }} % / {{ row.offline_cmn || '' }} %</span>
              </template>
            </el-table-column>
            <el-table-column label='负责人' prop='leader' align='center' min-width='100'>
              <template slot-scope='{row}'>
                <span>{{ row.leader_name || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='备注信息' prop='remark' align='center' min-width='140' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.remark || '' }}</span>
              </template>
            </el-table-column>
            <el-table-column label='店铺名称' prop='company_name' align='center' min-width='140' show-overflow-tooltip>
              <template slot-scope='{row}'>
                <span>{{ row.company_name || '' }}</span>
              </template>
            </el-table-column>
            <!--          <el-table-column label='创建时间' prop='created_at' min-width='140' align='center'>-->
            <!--            <template slot-scope='{row}'>-->
            <!--              <span :title='`修改时间：${ row.updated_at}`'>{{ row.created_at || '' }}</span>-->
            <!--            </template>-->
            <!--          </el-table-column>-->
          </el-table>
          <div style='text-align: right'>
            <pagination v-show='total>0'
                        :total.sync='total'
                        :page.sync='pageData.current_page'
                        :limit.sync='pageData.page_size'
                        @pagination='getList' />
          </div>
        </div>
        <div v-if='editMode'>
          <el-button size='medium' v-if="userPermissions.indexOf('product_selection_save')" type='primary'
                     icon='el-icon-plus' plain
                     :disabled='!pageSelectedList?.length!=0' @click='addPreBtn'>初选选品
          </el-button>
          <el-button size='medium' v-if="userPermissions.indexOf('lb_schedule_ack_selection')" type='warning'
                     icon='el-icon-plus' plain
                     :disabled='!pageSelectedList?.length!=0' @click='addPreOptBtn'>运营选品
          </el-button>
          <div style='display: inline-block;margin-left: 10px'>
             <span class='selected-total'>已选：
            <span style='color: #ff3176;font-size: 1.4em;font-style: italic;'>{{ pageSelectedList.length }}</span>  个
          </span>
            <span class='selected-total'>其中我负责的选品：
            <span style='color: #ff3176;font-size: 1.4em;font-style: italic;'>{{ mySelectionCount }}</span>  个
          </span>
            <span @click='emptyBtn' style='cursor: pointer; color: #ff3176'>清空选择</span>
          </div>
        </div>
      </div>
      <div>
        <div style='padding: 10px'>
          <el-tag effect='plain' type='primary' v-for='(item,index) in pageSelectedList' :key='index'
                  style='margin-right: 5px'>
            {{ index + 1 }}、[{{ item.id }}]{{ item.title }} / {{ item.leader_name }} / {{ item.category }} /
            {{ item.spec_name }}
          </el-tag>
        </div>
      </div>
      <!--    <el-dialog-->
      <!--        width="800px"-->
      <!--        title="钉钉消息通知"-->
      <!--        :visible.sync="showNotify"-->
      <!--        append-to-body>-->
      <!--      <NotifySend :id="lbObj.id" :action.sync="action" v-show="showNotify" @close="close"/>-->
      <!--    </el-dialog>-->
      <el-dialog title='确认增加以下选品' :visible.sync='allTable' :append-to-body='true' width='1000px'>
        <el-table :data='pageSelectedList' border>
          <el-table-column type='index' label='序号' width='50'></el-table-column>
          <!--        <el-table-column label='录入时间' prop='created_at' width='100' align='center'>-->
          <!--          <template slot-scope='{row}'>-->
          <!--            <span>{{ $utils.parseTime(row.created_at, '{y}-{m}-{d}') }}</span>-->
          <!--          </template>-->
          <!--        </el-table-column>-->
          <el-table-column label='商品名称' prop='title' min-width='160' header-align='center' align='left'
                           show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span>{{ row.title }}</span>
            </template>
          </el-table-column>
          <el-table-column label='产品图' prop='cover' width='70' align='center'>
            <template slot-scope='{row}'>
              <product-image :image-url='row.cover_url' />
            </template>
          </el-table-column>
          <el-table-column label='类目' prop='category_id' min-width='80' align='center'>
            <template slot-scope='{row}'>
              <span>{{ row.category || '' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='规格' prop='spec_name' align='center' min-width='100'
                           show-overflow-tooltip></el-table-column>
          <!--          <el-table-column label='合作方式' prop='mode' align='center' min-width='100'>-->
          <!--            <template slot-scope='{row}'>-->
          <!--              <span>{{ row.mode_alias || '' }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column label='日常价(元)' align='center' min-width='100'>
            <template slot-scope='{row}'>
              <span>{{ row.price || '/' }}</span>
            </template>
          </el-table-column>
          <el-table-column label='直播价(元)' align='center' min-width='100'>
            <template slot-scope='{row}'>
              <span>{{ row.lb_price || '/' }} </span>
            </template>
          </el-table-column>
          <!--          <el-table-column label='赠品' align='center' min-width='120' show-overflow-tooltip>-->
          <!--            <template slot-scope='{row}'>-->
          <!--              <span>{{ row.gift || '' }} </span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column label='独家机制' prop='other_activity' align='center' min-width='120' show-overflow-tooltip>-->
          <!--            <template slot-scope='{row}'>-->
          <!--              <span>{{ row.other_activity || '' }} </span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column label='坑位费(元)' prop='ad_space_fee' align='center' min-width='110'>-->
          <!--            <template slot-scope='{row}'>-->
          <!--              <span>{{ row.ad_space_fee || '-' }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <!--          <el-table-column label='线上/线下佣金' align='center' min-width='120'>-->
          <!--            <template slot-scope='{row}'>-->
          <!--              <span>{{ row.online_cmn || '' }} % / {{ row.offline_cmn || '' }} %</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column label='负责人' prop='leader' align='center' min-width='80'>
            <template slot-scope='{row}'>
              <span>{{ row.leader_name || '' }}</span>
            </template>
          </el-table-column>
          <!--          <el-table-column label='备注信息' prop='remark' align='center' min-width='140' show-overflow-tooltip>-->
          <!--            <template slot-scope='{row}'>-->
          <!--              <span>{{ row.remark || '' }}</span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column label='店铺名称' prop='company_name' align='center' min-width='120' show-overflow-tooltip>
            <template slot-scope='{row}'>
              <span>{{ row.company_name || '' }}</span>
            </template>
          </el-table-column>
        </el-table>
        <br>
        <el-row>
          <el-col :offset='11' :span='2'>
            <el-button type='primary' size='medium' @click='addSureBtn'>确定添加 {{ isAck == 'N' ? '《初选库》' : '《确认库》' }}
            </el-button>
          </el-col>

        </el-row>

      </el-dialog>
    </el-drawer>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import CategoryCascader from '@/pages/category/components/CategoryCascader'
import { mapGetters } from 'vuex'
import LeaderSearch from '@/pages/lb/components/LeaderSearch'
import ProductImage from '../product/ProductImage'
import SelectionDrawer from '@/pages/lb/product_selection/SelectionDrawer'
import NotifySend from '@/pages/lb/NotifySend'

export default {
  name: 'ProductTable',
  components: { SelectionDrawer, ProductImage, CategoryCascader, PageHeaderLayout, LeaderSearch, NotifySend },
  computed: {
    ...mapGetters(['userPermissions', 'userInfo']),
    leader: {
      get() {
        return { leader_id: this.userInfo.id, leader_name: this.userInfo.nickname }
      },
      set(val) {
        console.log(val)
        console.log('==========leader setter==============')
      }
    },
    mySelectionCount() {
      let myTotal = 0
      this.pageSelectedList.forEach((item) => {
        if (item.leader_id == this.leader.leader_id) {
          myTotal++
        }
      })
      return myTotal
    }
  },
  props: {
    lbObj: {
      type: Object,
      default() {
        return {}
      }
    },
    editMode: {
      type: Boolean,
      default() {
        return true
      }
    }
    // isAck: {
    //   type: String,
    //   default() {
    //     return 'N'
    //   }
    // }
  },
  data() {
    return {
      isAck: 'N',
      action: 'selection',
      tableRef: 'productMultipleTable',
      brandOptions: [],
      loadingBrandStatus: false,
      searchCondition: { category_id: null, title: null, leader_name: null, leader_id: null },
      dataList: [],
      total: 0,
      pageData: { page_size: 10, current_page: 1 },
      loading: false,
      open: false,
      copyRow: null,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      pageSelectedList: [],//预览数据列表
      loadingSelected: false,//加载已选
      // leader: {},

      drawerTitle: '产品库',
      allTable: false,
      showNotify: false
    }
  },
  methods: {
    onOpen() {
      this.init()
    },
    onClose() {
      // this.$refs['elForm'].resetFields()
    },
    close() {
      this.$emit('update:visible', false)
    },
    addPreBtn() {
      this.allTable = true
      this.isAck = 'N'
    },
    addPreOptBtn() {
      this.allTable = true
      this.isAck = 'Y'
    },
    setDrawerTitle() {
      let prefTitle = this.leader.leader_id ? `${this.lbObj.nickname}【${this.lbObj.platform_name}】的【${this.lbObj.selected_time}】选品` : '全部选品'
      return this.drawerTitle = `${prefTitle}`
    },
    selectable(row) {
      return !row.lb_product_id
      // if (row.lb_product_id) {
      //   return false
      // } else return true
    },
    addSureBtn() {
      let newData = [...this.pageSelectedList]
      this.saveSelections(newData)
    },
    mapData(data) {
      return data.map((item) => {
        let row = {}
        // row['id'] = item.lb_product_id || null
        row['product_id'] = item.id
        row['price'] = item.price || ''
        row['brand_support'] = item.brand_support || ''
        row['stock_info'] = item.stock_info || ''
        row['sample_info'] = item.sample_info || ''
        row['lb_price'] = item.lb_price || ''
        row['gift'] = item.gift || ''
        row['online_cmn'] = item.online_cmn || ''
        row['offline_cmn'] = item.offline_cmn || ''
        row['reason'] = item.reason || ''
        row['remark'] = item.remark || ''
        row['leader_id'] = item.leader_id || ''
        row['leader_name'] = item.leader_name || ''
        row['ad_space_fee'] = item.ad_space_fee || null
        row['is_ack'] = this.isAck || 'N'
        row['spec_name'] = item.spec_name || null
        //合作方式别名
        row['mode_alias'] = item.mode_alias || null
        //合作方式
        row['mode'] = item.mode
        row['price'] = item.price || null
        row['other_activity'] = item.other_activity || null
        return row
      })
    },
    async saveSelections(newData) {

      // let repeatData = {
      //   lb_id: this.lbObj.id,
      //   product_rows: this.mapData(this.pageSelectedList)
      // }
      let postData = {
        lb_id: this.lbObj.id,
        product_rows: this.mapData(newData)
      }
      //校验 直播日期前30天内，有出现>=2次被选品。 提示“该商品30天内已被选品n次！”
      let { list } = await this.$api.saveLbScheduleProductsCheckRepeat(postData)
      if (list && list.length > 0) {
        let msg = ''
        list.forEach((row) => {
          let product = this.pageSelectedList.find(value => value.id === row.product_id)
          if (product != undefined) {
            msg += `【商品(${product.title})已被选品${row.t}次】\n`
          }
        })
        this.$confirm(`直播日期前30天内 ${msg}，是否继续保存选品`, '警告', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.saveProducts(postData)
        }).catch(() => {
          this.msgWarn('取消操作')
        })
      } else {
        await this.saveProducts(postData)
      }
    },
    emptyBtn() {
      if (this.$refs.productMultipleTable)
        this.$refs.productMultipleTable.clearSelection()
    },
    async init() {
      this.pageSelectedList = []
      await this.handleQuery()
      this.setDrawerTitle()
      this.$nextTick(() => {
        this.emptyBtn()
      })
      // await this.loadLbScheduleProducts()
    },
    // 级联菜单组件change事件
    cascaderChange(v) {
      this.searchCondition.category_id = null
      if (v.length) {
        this.searchCondition.category_id = v[(v.length - 1)]
        // this.formData.category_ids = v
      }
    },
    async getList() {
      this.loading = true
      this.dataList = []
      let searchCondition = this.handleSearchCondition()
      Object.assign(searchCondition, { lb_id: this.lbObj.id }, this.pageData)
      let { list, pages } = await this.$api.getProductListExclude(searchCondition)
      this.$nextTick(() => {
        this.dataList = list || []
        this.pageData.current_page = pages.current_page || 1
        this.pageData.page_size = pages.page_size || 1
        this.total = pages.total
        this.loading = false
        //触发选中效果
        this.toggleSelection()

      })
    },

    async saveProducts(postData) {
      let info = await this.$api.saveLbScheduleProducts(postData)
      if (info) {
        this.$emit('saved', postData.lb_id)
        this.allTable = false
        this.$notify.success('操作成功')
        setTimeout(() => {
          this.close()
        }, 500)

        // this.$confirm('保存成功, 是否钉钉通知?', '提示', {
        //   confirmButtonText: '立即通知',
        //   cancelButtonText: '暂不通知',
        //   type: 'warning'
        // }).then(() => {
        //   this.showNotify = true
        // }).catch(() => {
        //   this.$message.info('暂不通知')
        //   this.close()
        // })
      }
    },
    async getBrand(force) {
      this.loadingBrandStatus = true
      if (this.brandOptions.length === 0 || force) {
        // let data = await this.$api.selectCustomerBrands({ customer_id: this.formData.customer_id })
        let { options } = await this.$api.getBrandOptions()
        this.brandOptions = options
        this.loadingBrandStatus = false
      }
    },
    setSelectBrand(val) {
      let option = this.brandOptions.find(value => value.id === val)
      this.searchCondition.brand_name = option ? option.name : ''
    },
    handleQuery() {
      this.dataList = []
      this.pageData.current_page = 1
      this.getList()
    },
    // 渲染选中
    toggleSelection() {
      const data = this.dataList ? this.dataList : []
      this.$nextTick(function() {
        data.forEach(row => {
          if (this.pageSelectedList.findIndex(value => value.id === row.id) > -1) {
            if (this.$refs['productMultipleTable'])
              this.$refs['productMultipleTable'].toggleRowSelection(row, true)
          }
        })
      })
    },
    handleSearchCondition() {
      //判断有没有保存全部的权限
      if (!(this.userPermissions.indexOf('product_selection_preview') > -1)) {
        this.searchCondition.leader_name = this.leader.leader_name
        this.searchCondition.leader_id = this.leader.leader_id
      }
      let cond = {}
      if (this.searchCondition.category_id) {
        cond['category_id'] = this.searchCondition.category_id
      }
      if (this.searchCondition.title) {
        cond['title'] = this.searchCondition.title
      }
      if (this.searchCondition.leader_id) {
        cond['leader_id'] = this.searchCondition.leader_id
      }
      if (this.searchCondition.brand_id) {
        cond['brand_id'] = this.searchCondition.brand_id
      }
      return cond
    },

    handleSelectRow(selection) {
      this.pageSelectedList = selection
    },
    selectedLeader(item) {
      // console.log(item)
      this.searchCondition.leader_id = item.leader_id
      this.handleQuery()
    }

  },
  mounted() {

  }
}
</script>

<style scoped>

.selected-total {
  text-align: left;
  line-height: 1;
  font-size: 1.3em;
  font-weight: 700;

}
</style>
